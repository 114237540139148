import styled from "styled-components";

export const TextBlock = styled.div`
ul li{
    list-style-type: disc;
    margin-left: 20px;
}
`
export const ContentBlock = styled.div`
padding:50px 0 80px 0;
@media (max-width:600px) {
    padding:40px 0;
}
`
export const Container = styled.div`
max-width:1230px;
margin:0 auto;
padding:0 15px;
`
export const Grid = styled.div`
display: grid;
grid-template-columns: 1fr;
gap: 10px 40px;
@media (min-width: 992px) {
   grid-template-columns: 5fr 7fr;
}
@media (min-width: 768px) and  (max-width:991px)  {
    grid-template-columns: 6fr 6fr;
}
`
export const Item = styled.div`
h2{font-size: 36px;line-height: 49px;
    @media (max-width:600px) {
        font-size: 28px;line-height: 36px;
    }
}
h4{margin:20px 0 15px 0;display:inline-block;width:100%;}
`
export const OfferLinks = styled.div`
background: #F4F0E9;
padding:16px 24px 24px 0px;
border-left: 18px solid #DED1BC;
@media (max-width:767px) {
margin-bottom:20px;
}

ul{margin:0;padding:0;}
ul li{list-style:none;margin:0;}

ul li a{
    color:#000;
    display: inline-block;
    font-weight:500;font-size:15px;line-height:26px;padding:4px 8px;margin:0;
    background: linear-gradient(to right, #DED1BC 50%, #F4F0E9 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-out;
  }

  ul li .active{
    background-position: left bottom;
  }

  ul li a:hover {
    background-position: left bottom;
    color:#000;
  }
}

@media (max-width:900px) {
    ul li{ width:100%;}
}
@media (max-width:600px) {
    ul li a{ font-size:16px;line-height:26px;}
}
div{
    display:flex;
    padding-left:14px;
    margin-top:20px;
    a{display:flex;}
    p{display: flex;align-items: center;justify-content: center;margin: 0; padding-left:12px;}
}
`
export const Text = styled.div`
display:flex;
padding-left:14px;
margin-top:20px;
a{display:flex;}
p{display: flex;align-items: center;justify-content: center;margin: 0; padding-left:12px;}
`