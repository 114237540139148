import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import { OfferLinks } from "./styled"

const OfferNav = ({ activeItem }) => {
    const { offersList } = useStaticQuery(
        graphql`
          query {
            offersList : allMarkdownRemark (sort: { fields: [frontmatter___title] }) {
                edges {
                  node {
                    frontmatter {
                      title
                      slug
                    }
                  }
                }
              }
          }
        `
      )
    return (
        <OfferLinks>
            <ul>
                {offersList.edges.map(({node}, index)=>{
                    return(
                        <li key={index}><Link to={`/czynnosci-notarialne/${node.frontmatter.slug}`} className={activeItem && activeItem===node.frontmatter.slug ? 'active' : 'inactive'}>{node.frontmatter.title}</Link></li>
                    )
                })}
            </ul>
        </OfferLinks>
    );
};
export default OfferNav;