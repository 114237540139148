import * as React from "react"
import Helmet from "react-helmet";

import Layout from "../components/Layout"
import Banner from "../components/Banner"
import AboutSection from "../components/Home/AboutSection"
import OfficeSection from "../components/Home/OfficeSection"
import OfferSection from "../components/Home/OfferSection"
import BenefitsSection from "../components/Home/BenefitsSection"
import BottomSection from "../components/Home/BottomLegalSection"

const IndexPage = () => (
  <>
    <Helmet htmlAttributes={{
    lang: 'pl',
    }}>

      <title>Kancelaria Notarialna M.Dąbrowska & J.Szeszkowska - Warszawa Centrum</title>
      <meta name="description" content="Kancelaria Notarialna Warszawa Centrum. Akty notarialne, wymagane dokumenty i opłaty. Informacje o czynnościach notarialnych. Notariusz śródmieście." />
    </Helmet>
    <Layout>
      <Banner />
      <AboutSection />
      <OfficeSection />
      <OfferSection />
      <BenefitsSection />
      <BottomSection />
    </Layout>
  </>
)

export default IndexPage
