import React from "react";
import { Link } from "gatsby"

import { OfferContent, Container, Grid, Item, TextBlock, Text, BtnOuter } from "./styled"

import OfferNav from "../../Offers/nav";

import Button from "../../Sections/Button"

const OfferSection = ({data}) => {
    return (
        <OfferContent>
            <Container>
                <h2>Czynności Notarialne</h2>
                <Grid>
                    <Item>
                        <TextBlock>
                            <Text>
                                <p>Jako przedstawiciele notariatu RP przy dokonywaniu czynności notarialnych czuwamy nad prawidłowym zabezpieczeniem praw i słusznych
                                    interesów stron oraz innych osób, dla których czynności te mogą powodować skutki prawne a także dochowujemy tajemnicy notarialnej.
                                    Przed dokonaniem czynności, prosimy o kontakt z kancelarią osobiście, telefonicznie lub drogą mailową, w celu uzyskania
                                    informacji w zakresie wymaganej dokumentacji oraz kosztów.</p>
                            </Text>
                            <BtnOuter>
                                <Button type="primary"><Link to="/czynnosci-notarialne/akty-notarialne">DOWIEDZ SIĘ WIĘCEJ</Link></Button>
                            </BtnOuter>
                        </TextBlock>
                    </Item>
                    <Item>
                    <OfferNav />
                        {/* <Links>
                        <ul>
                            <li><Link to="/offers/sporządzanie">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Link></li>
                            <li><Link to="/offers/sporządzanie1">Sed pretium lectus id massa porta suscipit.</Link></li>
                            <li><Link to="/offers/sporządzanie2">Nunc id velit quis odio placerat scelerisque sit amet eu quam.</Link></li>
                            <li><Link to="/offers/sporządzanie3">Morbi condimentum urna at elit imperdiet accumsan.</Link></li>
                            <li><Link to="/">Duis nec risus vitae risus sodales posuere.</Link></li>
                            <li><Link to="/">Phasellus quis orci non justo hendrerit varius.</Link></li>
                            <li><Link to="/">Proin sed est tempus, semper ligula a, semper ante.</Link></li>
                            <li><Link to="/">Etiam vel nisi id turpis rutrum egestas.</Link></li>
                            <li><Link to="/">Cras porttitor nunc eget est accumsan tempor.</Link></li>
                            <li><Link to="/">Pellentesque non erat ac enim dictum facilisis eget vitae nulla.</Link></li>
                            <li><Link to="/">Nunc vel nisl nec neque condimentum placerat.</Link></li>
                            <li><Link to="/">Proin hendrerit ex nec interdum pulvinar.</Link></li>
                            <li><Link to="/">Nam sollicitudin tortor vel lorem condimentum, id luctus nulla pretium.</Link></li>
                            <li><Link to="/">Nullam at libero nec nisi blandit consequat.</Link></li>
                        </ul>
                        </Links> */}
                    </Item>
                </Grid>
            </Container>
        </OfferContent>
    );
};
export default OfferSection;

