import React from "react";

import { BottomBlock, Container, Content } from "./styled"

const BottomSection = () => {
    return (
        <BottomBlock>
            <Container>
                <Content>
                    <h5>Zgodnie z ustawą z dnia 14 lipca 1991 roku prawo o notariacie: </h5>
                    <p>
                        Notariusz jest powołany do dokonywania czynności, którym strony są obowiązane lub pragną nadać formę notarialną.<br/>
                        Czynności notarialne mają charakter dokumentu urzędowego. Czynności notarialnych dokonuje się w języku polskim. W wypadku udziału w czynności osób nie władających językiem polskim konieczny jest udział tłumacza przysięgłego.
                    </p>
                    <p>
                        Notariusz dokonuje następujących czynności:
                    </p>
                    <ol>
                        <li>sporządza akty notarialne,</li>
                        <li>sporządza akty poświadczenia dziedziczenia,</li>
                        <li>podejmuje czynności dotyczące europejskiego poświadczenia spadkowego,</li>
                        <li>podejmuje czynności związane z zarządem sukcesyjnym przedsiębiorstwem osoby fizycznej i z tymczasowym przedstawicielem w zakresie udziału małżonka przedsiębiorcy w przedsiębiorstwie, na zasadach określonych w ustawie  z dnia 5 lipca 2018 r. o zarządzie sukcesyjnym przedsiębiorstwem osoby fizycznej i innych ułatwieniach związanych z sukcesją przedsiębiorstw,</li>
                        <li>sporządza poświadczenia: własnoręczność podpisu, zgodność odpisu, wyciągu lub kopii z okazanym dokumentem, datę okazania dokumentu („data pewna”), pozostawanie osoby przy życiu lub w określonym miejscu,</li>
                        <li>spisuje protokoły,</li>
                        <li>sporządza protesty weksli i czeków,</li>
                        <li>przyjmuje na przechowanie pieniądze, papiery wartościowe, dokumenty, dane na informatycznym nośniku danych, o którym mowa w przepisach o informatyzacji działalności podmiotów realizujących zadania publiczne,</li>
                        <li>prowadzi rejestry akcjonariuszy prostych spółek akcyjnych oraz podejmuje związane z tym czynności,</li>
                        <li>sporządza wypisy, odpisy i wyciągi dokumentów,</li>
                        <li>sporządza, na żądanie stron, projekty aktów, oświadczeń i innych dokumentów,</li>
                        <li>składa wnioski o wpis w księdze wieczystej wraz z dokumentami stanowiącymi podstawę wpisu w księdze wieczystej,</li>
                        <li>sporządza inne czynności wynikające z odrębnych przepisów.</li>
                    </ol>


                </Content>
            </Container>
        </BottomBlock>
    );
};

export default BottomSection;