import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { ContetnBlock, Container, Grid, Item, TextBlock, Image, Image1, GridInner } from "./styled";

const TopSection = () => {
  return (
    <ContetnBlock>
      <Container>
        <h1>Kancelaria Notarialna M.Dąbrowska & J.Szeszkowska</h1>
        <p>Jesteśmy notariuszami z dwudziestoletnią praktyką zawodową w notariacie.
          Od 2007 roku prowadzimy działalność w formie spółki cywilnej niezmiennie w tej samej lokalizacji w ścisłym
          centrum Warszawy.
          Dokonujemy czynności notarialnych zgodnie z obowiązującymi przepisami prawa oraz zasadami etyki zawodowej
          notariusza.
          Udzielamy informacji i wyjaśnień indywidualnie traktując każdego klienta i jego sprawę.
          Niezwykle ważna jest dla nas rzetelność.</p>
      </Container>
      <Grid>
        <Item>
          <GridInner>
            <Image><StaticImage src="../../../assets/images/image-1.jpg" alt="Magdalena Dąbrowska Notariusz" /></Image>
            <TextBlock>
              <div>
                <h3>Magdalena Dąbrowska <br /><span>Notariusz</span></h3>
                <p>Mediator wpisana na listę stałych mediatorów Sądu Okręgowego w Warszawie oraz na listę mediatorów
                  stałych Ośrodka Mediacyjnego Stowarzyszenia Notariuszy Rzeczypospolitej Polskiej.</p>
              </div>

              <p>Notariusz Magdalena Dąbrowska prowadzi kancelarię notarialną od marca 2007 roku. Ukończyła studia
                prawnicze na Wydziale Prawa i Administracji Uniwersytetu Warszawskiego, następnie odbyła aplikację
                notarialną i pracowała w charakterze asesora notarialnego w Izbie Notarialnej w Warszawie. Wizytator w
                Izbie Notarialnej w Warszawie.</p>
            </TextBlock>
          </GridInner>
        </Item>
        <Item>
          <GridInner className="item2">
            <TextBlock>
              <div>
                <h3>Joanna Szeszkowska <br /><span>Notariusz</span></h3>
                <p>Mediator wpisana na listę stałych mediatorów Sądu Okręgowego w Warszawie oraz na listę mediatorów
                  stałych Ośrodka Mediacyjnego Stowarzyszenia Notariuszy Rzeczypospolitej Polskiej.</p>
              </div>
              <p>Notariusz Joanna Szeszkowska prowadzi kancelarię notarialną od marca 2007 roku. Ukończyła studia
                prawnicze na Wydziale Prawa i Administracji Uniwersytetu Warszawskiego, następnie odbyła aplikację
                notarialną i pracowała w charakterze asesora notarialnego w Izbie Notarialnej w Warszawie. Ukończyła
                szkołę prawa niemieckiego i prawa europejskiego prowadzoną przez Centrum Prawa Niemieckiego na Wydziale
                Prawa i Administracji Uniwersytetu Warszawskiego.</p>
            </TextBlock>
            <Image1><StaticImage src="../../../assets/images/image-2.jpg" alt="Joanna Szeszkowska Notariusz" /></Image1>
          </GridInner>
        </Item>
      </Grid>
    </ContetnBlock>
  );
};
export default TopSection;